var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',_vm._b({directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"visible":_vm.visibleDialog},on:{"open":_vm.handlerOpen,"closed":_vm.handlerClose,"update:visible":function($event){_vm.visibleDialog=$event}}},'el-dialog',{title: '明细', width: '60%'},false),[_c('wz-table',{ref:"xTable",attrs:{"border":"","loading":_vm.loading,"data":_vm.tableData,"keep-source":true,"edit-config":{trigger: 'manual', mode: 'row'}}},[_c('vxe-column',{attrs:{"field":"compName","title":"企业名称","min-width":"160"}}),_c('vxe-column',{attrs:{"field":"monthName","title":"月份","min-width":"55"}}),_c('vxe-column',{attrs:{"field":"upleWeight","title":"上报产量(方)","min-width":"95"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isUpload===0)?_c('span',{staticClass:"red"},[_vm._v("未上报")]):[_vm._v(_vm._s(row.upleWeight))]]}}])}),_c('vxe-column',{attrs:{"field":"checkWeight","title":"核定产量(方)","edit-render":{},"min-width":"115"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number","placeholder":"请输入数值"},model:{value:(row.checkWeight),callback:function ($$v) {_vm.$set(row, "checkWeight", _vm._n($$v))},expression:"row.checkWeight"}})]}}])}),_c('vxe-column',{attrs:{"field":"upleTheoryWeight","title":"上报产能(方)","min-width":"95"}}),_c('vxe-column',{attrs:{"field":"checkTheoryWeight","title":"核定产能(方)","edit-render":{},"min-width":"115"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"number","placeholder":"请输入数值"},model:{value:(row.checkTheoryWeight),callback:function ($$v) {_vm.$set(row, "checkTheoryWeight", _vm._n($$v))},expression:"row.checkTheoryWeight"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.$refs.xTable.$refs.innerTable.isActiveByRow(row))?[_c('vxe-button',{on:{"click":function($event){return _vm.saveRowEvent(row)}}},[_vm._v("保存")]),_c('vxe-button',{on:{"click":function($event){return _vm.cancelRowEvent(row)}}},[_vm._v("取消")])]:[_c('vxe-button',{on:{"click":function($event){return _vm.editRowEvent(row)}}},[_vm._v("编辑")])]]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }