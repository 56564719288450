import http from '@/utils/http'

// 产能预警列表查询
export function getProductionValueWarnList (p) {
  return http.get('/material/analysis/getProductionValueWarnList', p)
}

// 产能预警-产能预警汇总数据查询
export function getWarnProductionTotalValue (p) {
  return http.get('/material/analysis/getWarnProductionTotalValue', p)
}

// 产能预警-企业产能明细数据查询
export function getCompDetailWarnProductionValue (p) {
  return http.get('/material/analysis/getCompDetailWarnProductionValue', p)
}

// 产能预警-编辑企业产能明细数据
export function editCompDetailWarnProductionValue (p) {
  return http.post('/material/analysis/editCompDetailWarnProductionValue', p)
}

// 产能预警-导出产能预警列表
export function exportProductionValueWarnList (p) {
  return http.postFormExcel('/material/analysis/exportProductionValueWarnList', p)
}
