<template>
  <el-dialog
    v-bind="{title: '明细', width: '60%'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <wz-table
      border
      ref="xTable"
      :loading="loading"
      :data="tableData"
      :keep-source="true"
      :edit-config="{trigger: 'manual', mode: 'row'}">
      <vxe-column
        field="compName"
        title="企业名称"
        min-width="160"
      ></vxe-column>
      <vxe-column
        field="monthName"
        title="月份"
        min-width="55"
      ></vxe-column>
      <vxe-column
        field="upleWeight"
        title="上报产量(方)"
        min-width="95">
        <template #default="{ row }">
          <span
            v-if="row.isUpload===0"
            class="red"
          >未上报</span>
          <template v-else>{{row.upleWeight}}</template>
        </template>
      </vxe-column>
      <vxe-column
        field="checkWeight"
        title="核定产量(方)"
        :edit-render="{}"
        min-width="115">
        <template #edit="{ row }">
          <vxe-input
            v-model.number="row.checkWeight"
            type="number"
            placeholder="请输入数值"
          ></vxe-input>
        </template>
      </vxe-column>
      <vxe-column
        field="upleTheoryWeight"
        title="上报产能(方)"
        min-width="95"
      ></vxe-column>
      <vxe-column
        field="checkTheoryWeight"
        title="核定产能(方)"
        :edit-render="{}"
        min-width="115">
        <template #edit="{ row }">
          <vxe-input
            v-model.number="row.checkTheoryWeight"
            type="number"
            placeholder="请输入数值"
          ></vxe-input>
        </template>
      </vxe-column>
      <vxe-column title="操作" width="160">
        <template #default="{ row }">
          <template v-if="$refs.xTable.$refs.innerTable.isActiveByRow(row)">
            <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
            <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>
          </template>
          <template v-else>
            <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
          </template>
        </template>
      </vxe-column>
    </wz-table>
  </el-dialog>
</template>

<script>
import {
  getCompDetailWarnProductionValue,
  editCompDetailWarnProductionValue
} from '@/apis/analysis/productivityWarning'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      loading: false,
      tableData: []
    }
  },
  methods: {
    editRowEvent (row) {
      const $table = this.$refs.xTable.$refs.innerTable
      $table.setActiveRow(row)
    },
    saveRowEvent (row) {
      const {
        checkWeight,
        checkTheoryWeight,
        compId,
        upleCompId,
        yearMonth
      } = row
      const params = {
        checkWeight,
        checkTheoryWeight,
        compId,
        upleCompId,
        yearMonth
      }
      this.editCompDetailWarnProductionValue(params)
    },
    cancelRowEvent (row) {
      const $table = this.$refs.xTable.$refs.innerTable
      $table.clearActived().then(() => {
        // 还原行数据
        $table.revertData(row)
      })
    },
    handlerClose () {
      this.$emit('update:editData', null)
      this.tableData = []
    },
    // 初始化表单
    handlerOpen () {
      this.getCompDetailWarnProductionValue()
    },
    // 编辑明细
    async editCompDetailWarnProductionValue (params) {
      const res = await editCompDetailWarnProductionValue(params)
      if (res.code == 200) {
        this.getCompDetailWarnProductionValue()
        this.$emit('refresh')
        this.$message.success('操作成功！')
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取明细
    async getCompDetailWarnProductionValue () {
      this.loading = true
      const res = await getCompDetailWarnProductionValue({
        compId: this.editData.compId
      })
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.compDetailWarnProductionValueDtoList.map((item) => {
          item.monthName = item.month + '月'
          return item
        })
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
