<template>
  <PageContainer>
    <div class="flex justify-around align-center mb-20">
      <div class="bg-red white w-150 h-60 flex flex-column justify-around align-center">
        产能不足企业 (家)
        <count-to :end-val="totals.lowCompCount" :duration="2000"/>
      </div>
      <div class="bg-yellow white w-150 h-60 flex flex-column justify-around align-center">
        产能过剩企业 (家)
        <count-to :end-val="totals.highCompCount" :duration="2000"/>
      </div>
      <div class="bg-blue white w-150 h-60 flex flex-column justify-around align-center">
        累计实际产量 (方)
        <count-to :end-val="totals.totalWeight" :duration="2000"/>
      </div>
      <div class=" bg-gray white w-150 h-60 flex flex-column justify-around align-center">
        本年设计产能 (方)
        <count-to :end-val="totals.totalTheoryWeight" :duration="2000"/>
      </div>
    </div>

    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="所属区域:">
        <el-input v-model.trim="searchForm.areaName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="所属企业:">
        <el-input v-model.trim="searchForm.compName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="预警类型:">
        <el-select v-model="searchForm.warnType">
          <el-option v-for="item in warnTypes" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="productivityWarning"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData"
      border
    >
      <template #buttons>
        <el-button icon="el-icon-download" type="primary" @click="exportTable" size="small">导出</el-button>
      </template>
      <vxe-column type="seq" field="seq" title="序号" align="center" width="80"/>
      <vxe-column field="areaName" title="所属区域" min-width="85"/>
      <vxe-column field="compName" title="企业名称" min-width="170"/>
      <vxe-colgroup title="去年累计">
        <vxe-column field="lastYearProductionWeight" title="去年累计实际产量(方)" min-width="150"/>
        <vxe-column field="lastYearTheoryWeight" title="去年设计产能(方)" min-width="123"/>
        <vxe-column field="lastYearRate" title="产能利用率(%)" min-width="140" >
          <template #header>
            <p>产能利用率(%)</p>
            <p>(实际产量/设计产能)</p>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-colgroup title="本年累计">
        <vxe-column field="thisYearProductionWeight" title="本年累计实际产量(方)" min-width="150"/>
        <vxe-column field="thisYearTheoryWeight" title="本年设计产能(方)" min-width="125"/>
        <vxe-column field="thisYearRate" min-width="140" title="产能利用率(%)">
          <template #header>
            <p>产能利用率(%)</p>
            <p>(实际产量/设计产能)</p>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-column field="increaseOrDecreaseRate" min-width="200" title="增减利用率(%)">
        <template #header>
          <p>增减利用率(%)</p>
          <p>(本年利用率-去年利用率)/本年利用率</p>
        </template>
      </vxe-column>
      <vxe-column field="warnTypeName" title="产能预测预警" min-width="100">
        <template #default="{ row }">
          <p :class="row.warnTypeClass">{{ row.warnTypeName }}</p>
        </template>
      </vxe-column>
      <vxe-column title="操作" min-width="70" field="operations">
        <template #default="{ row }">
          <el-button type="primary" @click="handlerDetail(row)">明细</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <detail-can-edit :visible.sync="detailVisible" :edit-data.sync="editData" @refresh="initList"/>
  </PageContainer>
</template>

<script>
import {
  getProductionValueWarnList,
  getWarnProductionTotalValue,
  exportProductionValueWarnList
} from '@/apis/analysis/productivityWarning'
import CountTo from 'vue-count-to'
import DetailCanEdit from './components/DetailCanEdit'
import { exportToxlsx } from '@/utils/tools'
export default {
  components: {
    CountTo,
    DetailCanEdit
  },
  data () {
    return {
      warnTypes: [
        { id: 0, name: '全部', colorClass: 'green' },
        { id: 1, name: '产能不足', colorClass: 'red' },
        { id: 2, name: '正常', colorClass: 'green' },
        { id: 3, name: '产能过剩', colorClass: 'yellow' }
      ],
      searchForm: {
        areaName: '',
        compName: '',
        warnType: 0
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 50,
        totalSize: 0
      },
      totals: {
        lowCompCount: 0,
        highCompCount: 0,
        totalWeight: 0,
        totalTheoryWeight: 0
      },
      loading: false,
      editData: null,
      detailVisible: false,
      sortVisible: false
    }
  },
  created () {
    this.initList()
    this.initTotalData()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      const res = await getProductionValueWarnList(params)
      this.loading = false
      if (res.code == 200) {
        this.tablePage.totalSize = res.data.totalSize
        const warnTypes = this.warnTypes
        this.tableData = res.data.rows.map((item) => {
          item.lastYearProductionWeight = XEUtils.round(
            item.lastYearProductionWeight,
            2
          )
          item.lastYearTheoryWeight = XEUtils.round(
            item.lastYearTheoryWeight,
            2
          )
          item.lastYearRate = XEUtils.round(item.lastYearRate, 2)
          item.thisYearTheoryWeight = XEUtils.round(
            item.thisYearTheoryWeight,
            2
          )
          item.thisYearRate = XEUtils.round(item.thisYearRate, 2)
          item.increaseOrDecreaseRate = XEUtils.round(
            item.increaseOrDecreaseRate,
            2
          )
          const warnTypeObj = warnTypes.find(
            ({ id }) => item.warnType == id
          ) || { name: '', colorClass: '' }
          item.warnTypeName = warnTypeObj.name
          item.warnTypeClass = warnTypeObj.colorClass
          return item
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    async initTotalData () {
      const res = await getWarnProductionTotalValue()
      if (res.code == 200) {
        this.totals = Object.assign(this.totals, res.data)
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },
    async exportTable () {
      const searchForm = this.searchForm
      const res = await exportProductionValueWarnList(searchForm)
      exportToxlsx(res, '产能预警列表')
    }
  }
}
</script>
